<template>
  <div class="consumables">
    <BaseDialog
      :title="ruleForm.id ? '修改维修记录' : '新增维修记录'"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="900px"
      top="5%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          label-width="124px"
          label-position="right"
          :inline="true"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="记录日期：">
            <el-date-picker
              v-model="ruleForm.record_time"
              type="datetime"
              :clearable="false"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择建立日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="维修工程师签名：">
            <el-select v-model="ruleForm.service_uid" placeholder="请选择">
              <el-option
                v-for="item in userNameList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记录人：">
            <el-select v-model="ruleForm.record_uid" placeholder="请选择">
              <el-option
                v-for="item in userNameList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报修人：">
            <el-select v-model="ruleForm.report_uid" placeholder="请选择">
              <el-option
                v-for="item in userNameList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="addr" label="报修故障：">
            <el-input
              v-model="ruleForm.fault_content"
              type="textarea"
              :rows="3"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item class="addr" label="故障原因：">
            <el-input
              v-model="ruleForm.fault_reason"
              type="textarea"
              :rows="3"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="维修方式：">
            <el-input
              v-model="ruleForm.repair_way"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="维修成本：">
            <el-input
              v-model="ruleForm.repair_cost"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="更换配件名称：">
            <el-input
              v-model="ruleForm.change_parts_name"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="配件数量：">
            <el-input
              v-model="ruleForm.parts_num"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="配件起用时间：">
            <el-date-picker
              v-model="ruleForm.parts_start"
              type="datetime"
              :clearable="false"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择建立日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="配件停用时间：">
            <el-date-picker
              v-model="ruleForm.parts_end"
              type="datetime"
              :clearable="false"
              value-format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择建立日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="配件寿命：">
            <el-input
              v-model="ruleForm.parts_long_life"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="配件来源：">
            <el-input
              v-model="ruleForm.parts_source"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { reactive, ref, defineExpose, defineEmits } from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import { ruleTimeYear } from '@/utils/tool.js'

const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
const userNameList = reactive([
  {
    name: localStorage.getItem('ms_username'),
    code: userInfo.uid,
    id: userInfo.uid,
  },
])
const ruleForm = ref({})
ruleForm.value.record_time = ruleTimeYear(new Date())
ruleForm.value.parts_start = ruleTimeYear(new Date())
ruleForm.value.parts_end = ruleTimeYear(new Date())
const visible = ref(false)
const autograph = reactive({
  care_uid: '',
})
const initData = (item) => {
  visible.value = true
  if (item.id) {
    ruleForm.value = item
    if (item.record_uid && item.record_uid !== userInfo.uid) {
      autograph.record_uid = JSON.parse(JSON.stringify(item)).record_uid
      ruleForm.value.record_uid = item.record_user.name
    }
    if (item.report_uid && item.report_uid !== userInfo.uid) {
      autograph.report_uid = JSON.parse(JSON.stringify(item)).report_uid
      ruleForm.value.report_uid = item.report_user.name
    }
    if (item.service_uid && item.service_uid !== userInfo.uid) {
      autograph.service_uid = JSON.parse(JSON.stringify(item)).service_uid
      ruleForm.value.service_uid = item.server_user.name
    }
  } else {
    ruleForm.value = {
      machine_id: item.machine_id,
      record_time: ruleTimeYear(new Date()),
      parts_start: ruleTimeYear(new Date()),
      parts_end: ruleTimeYear(new Date()),
    }
  }
}

// 保存
const saveLoad = ref(false)
const em = defineEmits(['refreshDataList'])
const savePatient = async () => {
  let url = ''
  if (ruleForm.value.id) {
    url = '/api/machine_repair/edit'
  } else {
    url = '/api/machine_repair/create'
  }
  if (ruleForm.value.record_uid !== userInfo.uid) {
    ruleForm.value.record_uid = autograph.record_uid
  }
  if (ruleForm.value.report_uid !== userInfo.uid) {
    ruleForm.value.report_uid = autograph.report_uid
  }
  if (ruleForm.value.service_uid !== userInfo.uid) {
    ruleForm.value.service_uid = autograph.service_uid
  }
  saveLoad.value = true
  let res = await service.post(url, ruleForm.value)
  saveLoad.value = false
  if (res.code === 0) {
    ElMessage.success(res.msg)
    visible.value = false
    em('refreshDataList')
  }
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
  .el-form-item {
    width: 47%;
    margin-right: 6%;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.addr {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
